
// ==============================================

//        TYPOGRAPHY

// ==============================================


//  Elements
// -----------------------------------------------

body {
  font-family: $font-plain;
  font-size: 17px;
  line-height: 1.3;
  color: $color-body-text;

  @include breakpoint(small only) {

  }
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-weight: $weight-bold;
}

h1,
.h1 {
  margin-top: 0;
  line-height: 1.1;
}

h2,
.h2 {
  margin-top: 0;
  font-size: 32px;

  // @include breakpoint(medium down) {
  // }
  // @include breakpoint(small only) {
  // }
}

h3,
.h3 {

}

h4,
.h4 {
  margin-top: 0;
  font-size: 32px;
}

.h2-small {
  font-size: 28px;
}


a {
  text-decoration: none;
}


sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.3em;
}


//  Helper classes
// -----------------------------------------------
