// ==============================================

//        GLOBAL

// ==============================================

body {
  background-color: $color-body-background;
}


// remove digit stepper in number inputs
input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}



// ==============================================

//        PAGE

// ==============================================

main {
  position: relative;
}

// wrapper inner 960px
.wrapper {
  max-width: $max-width;
  margin: 0 auto;

  // @include breakpoint(large down) {
  //   padding-left: 1rem;
  //   padding-right: 1rem;
  // }
}



/////////////////////////
.pack-icons{
  border: 1px solid green;
  @include bg(green);
  margin-top: 8rem;
}

// default size icon
.icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}



.separator-wave {
  content: '';
  position: absolute;
  top: -62px;
  left: 0;
  width: 97px;
  height: 66px;
  fill: red;
}
