// ==============================================

//        FUNCTIONS

// ==============================================


// A map of z layers
// All z-index values should be set there

$z-layers: (
  'modal'         : 100,
  'overlay'       : 90,
  'dropdown'      : 50,
  'pop-contact'   : 20,
  'header'        : 10,
  'nav-overlay'   : 9,
  'btn-slick'     : 8,
  'default'       : 1,
  'below'         : -1,
  'bottomless-pit': -10000
);

// Set z-index
@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }

  @return map-get($z-layers, $layer);
}
