
// ==============================================

//        FONDATION

// ==============================================


.fondation {
  text-align: center;
  color: $color-navy;
  background-image: linear-gradient(to bottom, #F9F9FB, #FFFFFF);

  .wrapper {
    padding: 70px 0;
  }

  h2 {
    max-width: 860px;
    margin: 0 auto 60px;
    font-weight: $weight-regular;

    span {
      color: $color-orange;
    }
  }
}


.fondation-item {
  padding: 0 1rem 3rem;

  .fondation-item__picto-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
    // @include bd(green,1);
  }

  svg {
    height: 100%;
    width: 100%;
    // @include bd(red,1);
  }

  &:nth-child(1) .fondation-item__picto-wrapper svg {
    height: 67px;
  }
  &:nth-child(2) .fondation-item__picto-wrapper svg {
    height: 68px;
  }
  &:nth-child(3) .fondation-item__picto-wrapper svg {
    height: 60px;
  }
  &:nth-child(5) .fondation-item__picto-wrapper svg {
    height: 48px;
  }
  &:nth-child(6) .fondation-item__picto-wrapper svg {
    height: 72px;
  }
}

.fondation-item__digit {
  font-size: 28px;
  font-weight: $weight-bold;
  margin-bottom: 6px;
}

.fondation-item__text {
  margin-top: 0;
  font-size: 17px;
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
  .fondation .wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .fondation-items {
    max-width: 760px;
  }
}

@include breakpoint(small only) {
  .fondation {
    display: none;
  }
}
