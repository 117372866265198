// ==============================================

//        FORMS

// ==============================================

input[type=text], input[type=email], input[type=tel], textarea, select {
  width: 100%;
  padding: 6px 14px;
  border: 1px solid $color-light-gray;
  border-radius: 12px;
  outline-color: transparent;
  outline-style: none;
  font-size: 17px;
  font-weight: $weight-bold;
  color: $color-navy;
  background-color: white;
  transition: all $trans-duration;

  &:focus {
    border: 1px solid rgba($color-navy-light,0.5);
  }

  &::placeholder {
    color: #999999;
  }
}

select {
  appearance: none;       /* Remove default arrow */
}

option {
  // color: green;
}

// wrapper for style arrow in select box
.select-wrapper {
  position: relative;

  // arrow down
  &:after {
    content: '';
    position: absolute;
    top: 1px;
    right: 15px;
    width: 16px;
    height: 100%;
    background-image: url(../images/svg/select-down.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
