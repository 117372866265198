
// ==============================================

//        NAV PROJECT - DESKTOP

// ==============================================


//  Nav - desktop
// -----------------------------------------------

.nav-project {
  // margin-bottom: 50px;
  padding: 0 1rem;
  position: relative;
  // @include bd(red,1,2);
}

.nav-project__list {
  @include list-inline-flex;
  display: flex;
  height: 100%;
  margin-bottom: 0;
}

.nav-project__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  // @include bd(white,0.4);
}

.nav-project__link {
  --r: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  border-radius: var(--r) var(--r) 0 0;
  padding: 5px 1rem 0 1rem;
  font-weight: $weight-bold;
  color: $color-sky-light;
  // text-transform: uppercase;
  text-shadow: 0 0 60px rgba($color-yellow, 0);
  transition: color 0.7s, text-shadow $trans-duration;
  // @include bg();
  line-height: 1.1;
  span { font-weight: 400; font-size: 1.25rem; }
  &.is-active:hover {
    color: $color-cyan;
  }
  &.is-active {
    color: $color-cyan;
    background-color: #15153E;
    &:after {
      background-color: $color-cyan;
      transform: scaleX(2);
    }
    .curved-corner { display: block;}
  }
    
  // separator
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    // left: 50%;
    height: 1px;
    width: 60px;
    background-color: $color-sky-light;
    transform: scaleX(0);
    transition: transform 0.7s, background-color 0.7s;
  }

  &:hover {
    color: $color-cyan;

    // &:after {
    //   background-color: $color-cyan;
    //   transform: scaleX(0.8);
    // }
  }

  p {
    margin-bottom: 1rem;
    font-size: 26px;
  }
}

//  Reverse corner
// -----------------------------------------------
.curved-corner {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  display: none;
  bottom: 0;
  transform: scaleX(-1);
  --w: 50px;
  --c: #15153E;
}
.curved-corner.--bottomleft:before,
.curved-corner.--bottomright:before{
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}
.curved-corner.--bottomleft {
  left: calc(var(--r)*-2);  
  &:before {
    bottom: 0;
    left: 0;
    box-shadow: calc(var(--r)*-1) var(--r) 0 0 var(--c);
  }
}
.curved-corner.--bottomright{
  right: calc(var(--r)*-2);  
  &:before {
    bottom: 0;
    right: 0;
    box-shadow: var(--r) var(--r) 0 0 var(--c);
  }
}
//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(medium down) {
  .nav-project__list {
    flex-direction: column;
    align-items: center;
  }
  .nav-project__item:not(:last-child) {
    margin-bottom: 1rem;
  }
  .nav-project__link {
    padding-bottom: 20px;
  }
}

@include breakpoint(small only) {
  .nav-project__link {

    &.is-active:after,
    &.is-active:hover:after {
      transform: scaleX(1);
    }

    p {
      font-size: 22px;
    }
  }
}
