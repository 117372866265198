
//  BronXXXX
// -----------------------------------------------
main {
  // background: linear-gradient(180deg, #15153E 0%, #005085 10%);
}

// ==============================================

//        PROJECT

// ==============================================


.project {
  position: relative;
  text-align: center;
  background-color: #005085;
  
  &:before {
    @include overlay();
    border-top: 1px solid #4391C4;
    width: 80%; height: 2px;
    margin:0 auto;
    z-index: 1;
    // background-image: url('../images/bg-star.png');
    // background-repeat: no-repeat;
    // background-position: bottom left;
  }

  .wrapper {
    max-width: 100%; 
    & > *:not(.project-slider) { 
      max-width: 1280px;
      margin:0 auto;
    }
    padding: 50px 0 0 0;
    // @include bd(white,0.5);
  }

  h2 {
    font-weight: $weight-regular;
    margin-bottom: 60px;
    padding: 0 1rem;
  }
  p.--small {
    font-size: 1rem;
    a { color:#fff}
  }
  p.--italic {
    font-style: italic;
  }
}

.project-slider {
  background: linear-gradient(180deg, #15153E 0%, #005085 100%);
  &:before {
    content:''; position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100vh;
    background-image: url('../images/bg-star.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  &:after {
    content:''; position: absolute; z-index: 1;
    bottom: 0; right: 0;
    width: 100%; height: 90vh;
    background-image: url('../images/bg-brain.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
  }
  & > * {
    z-index: 22;
  }
}

//  Slide
// -----------------------------------------------

.project-slide__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  margin-bottom: 40px;
  margin-left: 1rem;
  margin-right: 1rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
    border-radius: 30px;
  }

  h3 {
    position: relative;
    margin: 0;
    padding: 0 2rem;
    width: 100%;
    max-width: 800px;
    font-size: 32px;
  }
}

//  Addded 2023
// -----------------------------------------------
.parole-img {  
  img { max-width: 200px; margin:0 auto 1rem auto; }
  @include breakpoint(medium down) {
    figcaption { text-align: center !important; width: 100%;}
  }
  span { font-weight: 700; color:#57bdd0; }
}

.project-slide__inner {
  max-width: 1280px;
  margin: 0 auto;
  
  ul {
    list-style: none;
    li:before {
      content: "\2022";
      color: #F7A700; 
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  } 
  @include breakpoint(medium down) {
    p { text-align: center !important;}
  }
}

p.--center {
  text-align: center !important;
  strong { color:$color-cyan}
}

//  Slide row 1 - infos
// -----------------------------------------------

.project-slide {
  overflow: hidden;
  outline: none !important;
  padding-top: 2rem;
  
}


.project-slide__title-digits {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding: 0 1rem;
  font-size: 28px;
  color: $color-yellow;

  #project-slide2 & {
    max-width: 570px;
  }
}


.project-slide__row0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  p {
    max-width: 720px;
    &.increase {
      font-size: 1.5rem;
      color: $color-yellow
    }
  }
}
.project-slide__row1 {
  margin-bottom: 100px;
}

.project-slide__infos {
  position: relative;
  display: flex;
  flex-direction: column;
  // align-items: center;
  text-align: left;

  // &.--left { text-align: left;}

  &:nth-of-type(1) {
    padding-right: 3rem;
    padding-left: 6rem;

    // separator
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 100%;
      background-color: $color-cyan;
      box-shadow: 0 0 10px rgba($color-cyan, 1);
    }
  }
  &:nth-of-type(2) {
    padding-left: 3rem;
    padding-right: 6rem;
  }
  
  .icon {
    display: block;
    width: 60px;
    height: 60px;
    fill: $color-yellow;
    margin-bottom: .5rem;
  }

  p + ul {
    margin-top: 0 !important;
  }
}

.project-slide__infos-title {
  margin: 0rem;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-yellow;
}

.project-team {
  display: flex;
  figure {
    flex: 0 0 50%;
    margin-left: 0;
    text-align: left;
    color: $color-cyan;
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: 0 0 1rem 0;
    }
    figcaption {
      strong { font-size: 1rem;}
      font-size: .8rem;
    }
  }
}

//  Slide - zoom
// -----------------------------------------------

.project-slide__zoom {
  width: 100%;
  max-width: 760px;
  margin: 0 auto 60px;
  padding: 60px 100px 0 100px;
  border-radius: 30px;
  font-size: 20px;
  background-color: #005085;
  // background-image: url('../images/bg-star.png');
  // background-repeat: no-repeat;
  // background-position: top center;
  box-shadow: 0 0 40px rgba(#000E27, 0.4);
  h4 { text-align: left; text-transform: uppercase;}
  p, ul { text-align: left;}
  figure {
    display: flex;
    align-items: center;
    transform: translateX(-196px);
    width: 100%;
    img {
      max-height: 196px;
    }
    figcaption {
      font-size: 1rem;
      color: $color-cyan;
      text-align: left;
      padding-left: 1rem;
      strong { font-size: 1.25rem; }
    }
  }
  blockquote {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: italic;

    svg { 
      width: 50px; height: 40px;
      position: absolute;
      &:nth-of-type(1) { top:-30px; left:-60px;}
      &:nth-of-type(2) { bottom:-20px; right:-40px; transform: rotate(180deg);}
    }
  }
}

.project-slide__title-zoom {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 700;
  color: $color-cyan;
  text-shadow: 0 0 10px rgba(#5FC8D8, 0.6);
}

.project-slide__title-zoom:nth-of-type(2) + p {
  font-size: 24px;
}


.project-slide__video {
  width: 100%;
  max-width: 570px;
  margin: 50px auto 1rem;
  border-radius: 30px;
  overflow: hidden;
}

.project-slide__video-caption {
  width: 100%;
  max-width: 570px;
  margin: 0 auto 70px;
  font-size: 17px;
}

.project-slide__btn-video {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.is-hidden {
    display: none;
  }

  &:hover {
    .picto-play {
      transform: scale(1.1);
    }
  }
}

.picto-play {
  width: 80px;
  height: 80px;
  transition: transform 0.25s;
  pointer-events: none;
}

.embed-container { 
  @include aspect-ratio(16/9);
} 





//  Slide - CTA
// -----------------------------------------------

.project-slide__cta {
  width: 100%;
  max-width: 990px;
  margin: 0 auto 4rem auto;
  padding: 0 100px;
  font-size: 20px;

  p {
    margin-bottom: 30px;
  }
}

.project-slide__title-cta {
  width: 100%;
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  font-size: 26px;
  color: $color-yellow;

  & + p {
    max-width: 640px;
    margin: 0 auto 2rem auto;
  }
}






//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
  .project-slide__infos {
    &:nth-of-type(1) {
      padding-left: 2rem;
      padding-right: 3rem;
    }
    &:nth-of-type(2) {
      padding-left: 3rem;
      padding-right: 2rem;
    }
  }

}

@include breakpoint(medium down) {
  .project-team {
    flex-direction: column;
    figure {
      flex: unset;
      margin: 0 3rem 2rem 3rem;
      text-align: center;
    }
    img {
      margin: 0 auto 1rem auto !important;
    }
  }
  .project-slide__zoom {
    padding: 2rem 1rem !important;
    blockquote {
      margin: 0 2rem;
      svg:nth-of-type(1) { top:-30px; left:-40px; transform: scale(.6);}
      svg:nth-of-type(2) { bottom:-10px; right:-30px; transform: scale(.6) rotate(180deg);}
    }
    figure { 
      margin-left: 0;
      transform: unset;
      img { display: none;}
    }
  }
  
  

  .project-slide__row0,
  .project-slide__row1 {
    max-width: 700px;
    margin-bottom: 60px;
    padding: 0 2rem;
  }
  .project-slide__infos {

    .icon { margin: 0 auto 1rem auto;}
    .project-slide__infos-title { text-align: center;}
    &:nth-of-type(1) {
      padding-right: unset;
      padding-left: unset;
      margin-bottom: 3rem;
      padding-bottom: 3rem;
  
      // separator
      &:after {
        top: unset;
        bottom: 0;
        left: 50%;
        right: unset;
        transform: translateX(-50%);
        width: 140px;
        height: 1px;
        @include breakpoint(medium down) {
          width: 80%;
        }
      }
    }
    &:nth-of-type(2) {
      padding-right: unset;
      padding-left: unset;
    }
  }
  .project-slide__zoom {
    padding: 60px 2rem;
  }
  .project-slide__cta {
    padding: 0 2rem;
  }
}

@include breakpoint(small only) {
  .project-slide__header h3 {
    padding: 0 1rem;
    font-size: 22px;
    line-height: 1.2;
  }

  .project-slide__row1 {
    padding: 0 1rem;
  }

  .project-slide__zoom {
    padding: 60px 1rem;
  }
  .project-slide__cta {
    padding: 0 1rem;
  }
}

