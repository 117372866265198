// ==============================================

//        CUSTOM PLAYER AUDIO

// ==============================================


//  Audio player
// -----------------------------------------------

.paudio {
  position: relative;
  display: flex;
  align-items: center;
  // @include bd();
}

.btn-play-pause {
  width: 50px;
  height: 50px;
  padding: 0;
  background-color: $color-yellow;
  outline-color: transparent;
  outline-style: none;

  // gradient
  &:before {
    background-image: radial-gradient(
      circle farthest-side at 80% 100%,
      #F7C600 10%, $color-yellow 80%
    );
    transform: translateX(80px);
  }

  // icon
  &:after {
    @include overlay();
    background-position: center center;
    background-repeat: no-repeat;
  }

  &:hover:before {
    transform: translateX(12px);
  }

  &.is-play:after {
    background-image: url('../images/svg/icon-play.svg');
  }
  &.is-pause:after {
    background-image: url('../images/svg/icon-pause.svg');
  }
}


.paudio__meta {
  position: relative;
  flex: 1;
  margin-left: 30px;
  font-size: 0;
  // @include bd(green,0.5);
}


.paudio__progress {
  height: 6px;
  width: 100%;
  appearance: none;
}

.paudio__progress[value]::-webkit-progress-bar {
  background-color: $color-sky-light;
  border-radius: 3px;
}

.paudio__progress[value]::-webkit-progress-value  {
  background-color: $color-navy-light;
  border-radius: 3px;
}

.paudio__currenttime {
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 12px;
  font-weight: $weight-regular;
  color: $color-sky;
}



              // .play-pause {
              //   width:100px;
              //   height:100px;
              //   display:inline-block;
              //         display: none;
              //   position:absolute;
              //   left:-33px;
              //   top:7px;
              //   background-image: url('http://dev.steelehouse.com/codepen/play-dark.gif');
              //   border-radius:20%;
              //   background-repeat:no-repeat;
              //   cursor:pointer;
              //
              //   // This doesn't actually refresh the gif cache, wish it did, but it doesn't
              //   &.paused  { background-image: url('http://dev.steelehouse.com/codepen/pause-dark.gif');}
              //   &.pausing  { background-image: url('http://dev.steelehouse.com/codepen/play-to-pause-dark.gif');}
              //   &.playing  {
              //       background-image: url('http://dev.steelehouse.com/codepen/pause-to-play-dark.gif');
              //   }
              // }
