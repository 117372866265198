// ==============================================

//        MIXINS

// ==============================================





//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}



//  Utils
// -----------------------------------------------

// horizontal list
@mixin list-inline-flex() {
  @include list();
  display: flex;
}

// horizontal list
@mixin list-inline() {
  @include list();

  > li {
    display:inline-block;
  }
}


// list without bullets
@mixin list() {
  list-style: none;
  padding-left: 0;
}


// clearfix for floats
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}


// Debug with background
@mixin bg($color:#ff0000, $opacity:0.1) {
  background-color: rgba($color, $opacity);
}

// Debug with border
@mixin bd($color:#000000, $opacity:0.15, $thickness:1) {
  outline: #{$thickness}px dotted rgba($color, $opacity);
}



// no gutter for columns >>> to apply on column NOT row
@mixin collapse-left() {
  padding-left: 0;
  // padding-right: map-get($grid-column-gutter, medium)/2;
}
@mixin collapse-right() {
  // padding-left: map-get($grid-column-gutter, medium)/2;
  padding-right: 0;
}
@mixin collapse-all() {
  padding-left: 0;
  padding-right: 0;
}
@mixin collapse-middle() {
  padding-left: 0.9375rem / 2;
  padding-right: 0.9375rem / 2;
}


// Set gutter on column
@mixin column-gutter($left:null, $right:null) {
  @if ($left) {
    padding-left: $left;
  }
  @if ($right) {
    padding-right: $right;
  }
}

// foundation mixin : set gutter left AND right
// @include grid-column-gutter(25px);
// foundation mixin : collapse gutter left AND right
// @include grid-column-collapse();



/// Create burger menu button with icon
/// @param {Number} $icon-width — Icon width
/// @param {Number} $icon-height — Icon height
/// @param {Number} $line-height — Symbol bar height
/// @param {Hex} $color — Icon color
/// @param {Float} $transition-time — Animation duration
///
/// @returns {Number}
///
/// @example scss - Usage
/// @include icon-burger($icon-width:18px, $icon-height:16px, $line-height:2px);
///
@mixin icon-burger($icon-width, $icon-height, $line-height, $color:#000000, $transition-time:0.2s) {

  $icon-centerY: ($icon-height - $line-height) / 2;

  // icon wrapper
  position: relative;
  width: $icon-width;
  height: $icon-height;

  // bars
  span {
    position: absolute;
    display: block;
    height: $line-height;
    width: 100%;
    background-color: $color;
    border-radius: $line-height;
    opacity: 1;
    transition: $transition-time ease-in-out;
  }

  // state burger
  span:nth-child(1) {
    top: 0;
    transition-delay: 0.1s;
  }
  span:nth-child(2) {
    top: $icon-centerY;
    transition-delay: 0;
  }
  span:nth-child(3) {
    top: $icon-centerY;
    transition-delay: 0;
  }
  span:nth-child(4) {
    top: $icon-height - $line-height;
    transition-delay: 0.1s;
  }

  // state close
  &.is-active {
    span:nth-child(1) {
      transform: translateY($icon-centerY);
      transition-delay: 0s;
      opacity: 0;
    }
    span:nth-child(2) {
      transform: rotate(45deg);
      transition-delay: 0.1s;
    }
    span:nth-child(3) {
      transform: rotate(-45deg);
      transition-delay: 0.1s;
    }
    span:nth-child(4) {
      transform: translateY(-$icon-centerY);
      transition-delay: 0s;
      opacity: 0;
    }
  }
}



//  Custom
// -----------------------------------------------

// full overlay block in absolute position
@mixin overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}



// create aspect-ratio pseudo-element pusher in container
// @include aspect-ratio(16, 9);  -> Width and height
// @include aspect-ratio(1,77);  -> Ratio calculated from width / height
// @include aspect-ratio();  -> No arguments defaults to a 1:1 ratio
// 1:1 aspect ratio = 1 / 1 = 1 = padding-top: 100%
// 4:3 aspect ratio = 3 / 4 = 0.75 = padding-top: 75%
// 3:2 aspect ratio = 2 / 3 = 0.66666 = padding-top: 66.67%
// 16:9 aspect ratio = 9 / 16 = 0.5625 = padding-top: 56.25%
@mixin aspect-ratio($width-or-ratio:1, $height:null) {
  position: relative;
  
  &:before {
    content: '';
    display: block;
    $padding: if($height, percentage($height/$width-or-ratio), percentage(1/$width-or-ratio));
    padding-top: $padding;
  }

  // pull-up child in container
  > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}