
// ==============================================

//        MODAL MENTIONS LEGALES

// ==============================================

.modal-mentions {
  position: relative;
  display: none;
  width: 100%;
  max-width: 900px;
  padding: 0;
  background: none;
  overflow: visible;
}

// inner for btn-close
.modal-mentions__inner {
  // position: relative;
  // display: none;
  // width: 100%;
  // max-width: 900px;
  // padding: 0 0 40px 0;
  padding-bottom: 40px;
  border-radius: $border-radius;
  background-color: white;
  color: $color-navy-light;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow: hidden;

  // top / bottom gradient for smooth scroll
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 40px;
    width: calc(100% - 16px);
    height: 2rem;
    background-image: linear-gradient(to top, white, rgba(white,0));
  }

  // .fancybox-close-small {
  //   top: 20px;
  //   right: 10px;
  // }

  .content-header {
    position: relative;
    border-bottom: 1px solid #eee;
    padding: 0 20px;

    // top / bottom gradient for smooth scroll
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: -2.1rem;
      width: calc(100% - 16px);
      height: 2rem;
      background-image: linear-gradient(to bottom, white, rgba(white,0));
    }
  }

  .content-scroll {
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding: 0 20px 2rem 20px;
  }

  h2,
  h3 {
    color: $color-navy;
  }

  h2 {
    margin: 20px 0;
  }

  h3 {
    margin: 30px 0 -8px;
    line-height: 1.3;
  }

  a {
    color: $color-orange;
    text-decoration: underline;
  }
}
