
// ==============================================

//        HOME

// ==============================================

.home {
  background-color: $color-navy-light;
  // @include bg(red,1);
}

.home-visu__ratio {
  position: relative;
  padding-top: 51.7%;      // aspect-ratio
}



.home-visu__ratio-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  
  
  &:after {
    @include overlay();
    background-image: linear-gradient(to bottom, $color-navy 0%, rgba($color-navy,0) 60%);
  }
  
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
   
    h2 {
      font-weight: 400;
      span {
        padding: 0 .5rem;
        background-color: $color-yellow;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
    }
  }
}

.home-visu__img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  img { object-fit: (cover); width: 100%; height: 100%; }
}
.home-visu__video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // @include bd(teal,1,2);
}


.home-visu__title {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-top: 100px;
  // padding-left: 100px;
  @include fluid-type(34px, 40px, 800px, 1200px);
  // @include bd(white,0.8);

  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  .yellow { color: $color-yellow; } 
  strong {
    @include fluid-type(42px, 48px, 800px, 1200px);
  }
}



//  MQ Breakpoints
// -----------------------------------------------

@media screen and (max-width: $smallmed-width) {
  .home-visu__ratio {
    position: relative;
    padding-top: 0;      // remove aspect-ratio
  }

  .home-visu__ratio-inner {
    position: static;
    background-image: url('../images/bg-home.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      @include overlay();
      background-color: rgba($color-navy, 0.4);
    }
  }

  .home-visu__ratio-inner h2 { text-align: center;}
  .home-visu__video {
    display: none;
  }

  .home-visu__title {
    // max-width: 370px;
    margin-top: 360px;
    margin-bottom: 50px;
    padding: 0 2rem;
    line-height: 1.3;
    text-align: center;
    // @include bd(white,0.8);
  }
}
