
// ==============================================

//        NEWSLETTER

// ==============================================


.newsletter {
  color: $color-navy;
  background-image: linear-gradient(to bottom, $color-light-gray, #FFFFFF);

  .wrapper {
    display: flex;
    max-width: 620px;
    &:nth-child(1) { padding: 70px 0 0 0; }
    &:nth-child(2) { padding: 10px 0 30px 0; }

    // > * {
    //   @include bd(red,1);
    // }
    // @include bd();
  }
}

.newsletter-infos {
  flex: 1 0 auto;
  width: 280px;
  padding: 0 50px 0 1rem;

  h2 {
    margin: 5px 0 12px;
    font-size: 28px;
    color: $color-navy-light;
  }

  .contact-infos__text {
    margin-top: 0;
    font-size: 17px;
  }
}


.form-newsletter {
  padding: 0 1rem;

  input,
  .select-wrapper {
    margin-bottom: 0.5rem;
  }

  button {
    margin-top: 1rem;
  }
}

.form-newsletter__feedback {
  font-size: 15px;
  color: rgba($color-navy, 0.5);
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(780px down) {
  .newsletter .wrapper {
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    padding: 70px 1rem;
  }
  .newsletter-infos {
    flex: 1 0 auto;
    width: unset;
    margin-bottom: 2rem;
    padding: 0;
    text-align: center;

    h2 br {
      display: none;
    }
  }
  .form-newsletter {
    padding: 0 2rem;
    text-align: center;
  }
}
