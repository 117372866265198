
// ==============================================

//        FOOTER

// ==============================================


//  Row 1 - blue
// -----------------------------------------------

.footer1 {
  padding: 40px 0;
  font-size: 14px;
  background-color: $color-navy;

  .row > .column {
    display: flex;
    justify-content: center;
    align-items: center;
    // @include bd(gold,0.5);
  }
  // @include bd(white,0.5);
}


.logo-donconfiance {
  width: 84px;
  height: 84px;
  margin-right: 20px;
}

.btn-social:first-child {
  margin-right: 2rem;
}


.footer__c3 {
  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer__c3-text {
    max-width: 266px;
  }
}


.footer__c2 {
  .logo-icm-footer {
    width: 200px;
    height: 50px;
  }
}




//  MQ Breakpoints
// -----------------------------------------------
@include breakpoint(xlarge) {
  .footer__c2 {
    // flex-basis: 23%;
    // max-width: 23%;
  }
  .footer__c3 {
    justify-content: center !important;
    // flex-basis: 43%;
    // max-width: 43%;
  }
}

@include breakpoint(large down) {
  .footer1 .wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@include breakpoint(medium down) {
  .footer1 .wrapper {
    max-width: 500px;
  }
  .footer__c1,
  .footer__c3 {
    flex-direction: column;
    text-align: center;
  }
  .logo-donconfiance {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
  .footer__c2 {
    padding: 2rem 0;
  }
}



//  Row 2 - white
// -----------------------------------------------

.footer2 {
  padding: 50px 2rem;
  font-size: 16px;
  text-align: center;
  color: #484848;

  a {
    color: #484848;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
