



.paudio__currenttime {
  display: none !important;
}

.home .wrapper {
  // @include bd(green,1,2);
}
.cta .wrapper {
  // @include bd(white,0.2,1);
}


// ==============================================

//        HEADER

// ==============================================


//////////////////////
// .header {
//   position: absolute;
// }
//
// @media screen and (min-width: $smallmed-width) {
//   .header.is-sticky {
//     position: fixed;
//   }
// }
//////////////////////


.header .wrapper {
  // @include bd(white,0.6,1);
}

// @media screen and (max-width: $smallmed-width) {
//   .header {
//     position: fixed;
//     transform: translateY(-400px);
//   }
// }

// @include breakpoint(large down) {
//   .header {
//     display: none;
//   }
// }

// ==============================================

//        HOME

// ==============================================

// .project,
// .fiscal,
// .example,
// .calendar,
// .fondation,
// .footer {
//   .row {
//     @include bd(red, 0.5,1);
//
//     > .column {
//       @include bd(green, 0.5,1);
//     }
//   }
// }

// .project-slider__controls {
//   display: none;
// }




// .home-visu {        @include bd(red,1,3);    }
// .home-visu__ratio {          @include bd(blue,1,5);   }
// .home-visu__texts {
//   @include bd(red,1,2);
// }
//
// .home-visu__title {
//   @include bd();
// }




//
// @media (any-hover: hover) {
//   .btn-header {
//     color: pink !important;
//   }
// }
//
// @media (hover: hover) {
//   .btn-header {
//     background-color: blue !important;
//   }
// }
