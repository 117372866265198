// ==============================================

//        SLICK THEME CUSTOM UI

// ==============================================


// container for btn next / prev
.project-slider__controls {
  position: relative;
  z-index: 33;
}


//  Arrows
// -----------------------------------------------

.slick-prev,
.slick-next {
  position: fixed;
  z-index: z('btn-slick');
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  cursor: pointer;
  transition: transform 0.5s, opacity $trans-duration, visibility $trans-duration;

  &.is-active {
    transform: translateX(0) translateY(-50%);
  }
}


// btn hidden by default
.slick-prev {
  left: -10px;
  transform: translateX(-255px) translateY(-50%);
}
.slick-next {
  right: -10px;
  transform: translateX(255px) translateY(-50%);
}

// state when slide is sliding after click
.slick-prev.is-sliding,
.slick-next.is-sliding {
  .btn-slick__inner {
    transition-timing-function: ease-in-out !important;

    span,
    .icon {
      opacity: 0;
    }
  }
}
.slick-prev.is-sliding .btn-slick__inner {
  transform: translateX(-10px) !important;
}
.slick-next.is-sliding .btn-slick__inner {
  transform: translateX(10px) !important;
}



.btn-slick__inner {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  width: 125px;
  height: 100px;
  padding: 22px;
  font-size: 13px;
  font-weight: $weight-bold;
  text-transform: uppercase;
  line-height: 1.15;
  color: white;
  background-color: $color-yellow;
  box-shadow: 0 2px 12px 0 rgba($color-navy,0.7);
  overflow: hidden;
  transition: transform $trans-duration;
  transition-timing-function: ease-out;

  // gradient
  &:before {
    @include overlay();
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
  }

  span {
    transition: transform $trans-duration, opacity 0.2s;
    transition-timing-function: ease-out;
  }

  .icon {
    width: 15px;
    height: 30px;
    fill: white;
    transition: transform $trans-duration, opacity 0.2s;
    transition-timing-function: ease-out;
  }


}

.slick-prev {
  .btn-slick__inner {
    justify-content: flex-end;
    border-radius: 0 22px 22px 0;
    text-align: left;
  }

  .btn-slick__inner:before {
    background-image: radial-gradient(
      circle farthest-side at 80% 100%,
      #F7C600 10%, $color-yellow 80%
    );
    // #F7C600 10%, $color-yellow 80%
    transform: translateX(180px);
  }

  span {
    margin-left: 20px;
  }

  .icon {
    transform: scaleX(-1);
  }
}

.slick-next {
  .btn-slick__inner {
    border-radius: 22px 0 0 22px;
    text-align: right;
  }

  .btn-slick__inner:before {
    background-image: radial-gradient(
      circle farthest-side at 20% 100%,
      #F7C600 10%, $color-yellow 80%
    );
    transform: translateX(-180px);
  }

  span {
    margin-right: 20px;
  }
}



.slick-prev:hover,
.slick-next:hover {
  .btn-slick__inner:before {    // gradient
    opacity: 1;
    transform: translateX(0);
  }
}

.slick-next:hover {
  .btn-slick__inner {
    transform: translateX(-10px);
    transition-timing-function: ease-in-out;
  }
  span {
    transform: translateX(15px);
    transition-timing-function: ease-in-out;
  }
  .icon {
    transform: translateX(20px);
    transition-timing-function: ease-in-out;
  }
}

.slick-prev:hover {
  .btn-slick__inner {
    transform: translateX(10px);
    transition-timing-function: ease-in-out;
  }
  span {
    transform: translateX(-15px);
    transition-timing-function: ease-in-out;
  }
  .icon {
    transform: translateX(-20px) scaleX(-1);
    transition-timing-function: ease-in-out;
  }
}

// .slick-prev:active,
// .slick-next:active {
//   span {    opacity: 0.8;    }
//   .icon {    opacity: 0.8;    }
// }





.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  // display: none !important;
}


//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(small only) {
  .project-slider__controls {
    display: none;
  }
}
