// ==============================================

//        VARIABLES

// ==============================================

//  Colors
// -----------------------------------------------
$color-body-background : #f9f9fb;
$color-body-text       : white;

$color-navy        : #062945;
$color-navy-light  : #262C67;
$color-orange      : #E84E1B;
$color-orange-light: #F27349;
$color-yellow      : #F7A700;
$color-yellow-light: #FFD424;
$color-cyan        : #66E5F8;
$color-sky         : #919BB6;
$color-sky-light   : #A7C2D2;
$color-light-gray  : #DFE1E8;



//  Fonts
// -----------------------------------------------
$font-plain : 'Roboto Condensed', sans-serif; // $weight-regular : 400,
$font-quote : 'Merriweather', serif;

// Font-weights
// $weight-thin:     200;
// $weight-light:    300;
$weight-regular:  400;
// $weight-medium:   500;
$weight-bold:     700;
// $weight-xbold:    800;
// $weight-black:    900;

// alias
$weight-normal: $weight-regular;


//  Size / Units
// -----------------------------------------------
// Container's maximum width
$max-width      : 1200px;
$border-radius  : 12px;
$trans-duration : 0.25s;
$smallmed-width : 750px;

:root {
  // --header-height      : 64px;
  --wrapper-padding    : 2rem;

  @media screen and (max-width: 640px) {
    --wrapper-padding    : 1rem;
  }
}
