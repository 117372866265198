// ==============================================

//        FANCYBOX

// ==============================================


//  Fancybox styles
// -----------------------------------------------

// padding for btn-close
.fancybox-slide {
  padding: 28px;
}

.fancybox-bg {
  background-color: $color-navy;
}

// btn close on top right of modal container
.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  text-align: center;
  background-color: #DFE1E8;
  border-radius: 50%;
  opacity: 1;
  transform: translate(50%, -50%);
  transition: background-color $trans-duration;

  &:hover {
    background-color: $color-sky-light;

    svg path {
      fill : white;
    }
  }

  svg {
    height: 12px;
    width: 12px;
    margin: 0 auto;

    path {
      fill : $color-navy;
      transition: fill $trans-duration;
    }
  }
}
