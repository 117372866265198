
// ==============================================

//        CALCULATOR

// ==============================================


.calcul {
  position: relative;
  text-align: center;
  color: $color-navy;
  background-image: linear-gradient(to bottom, $color-light-gray, #FFFFFF);

  .wrapper {
    position: relative;
    padding: 50px 2rem 190px;
  }
}


//  Nav slider
// -----------------------------------------------

.nav-calcul {
  position: absolute;
  top: -130px;
  left: 0;
  width: 100%;
  height: 130px;
  overflow: hidden;
}

.nav-calcul__wave {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% / 3);
  height: 130px;
  transition: transform 0.6s ease-in-out;

  &[data-position="2"] {
    transform: translateX(100%);
  }

  // svg centered in container
  svg {
    position: absolute;
    top: 9px;
    left: 50%;
    width: 400px;
    height: 130px;
    transform: translateX(-50%);
    fill: $color-light-gray;
  }
}

.nav-calcul__list {
  @include list-inline-flex;
  position: relative;
  margin-top: 0;
  height: 100%;
}

.nav-calcul__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  height: 100%;
}

.nav-calcul__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin-top: 1rem;
  font-weight: $weight-bold;
  color: $color-navy;

  .icon {
    width: 30px;
    height: 40px;
    margin-bottom: 12px;
    fill: $color-navy;
  }
}


//  Slider
// -----------------------------------------------

.calcul-slider {
  user-select: text;
}

.calcul-slide {
  h2 {
    margin-bottom: 40px;
    font-weight: $weight-regular;
    color: $color-navy;
  }
}

.calcul-slide__inner {
  max-width: 900px;
  margin: 0 auto;
}



//  Forms
// -----------------------------------------------

.form-calculator {
  margin: 0 auto 50px;
  max-width: 580px;
}



.form-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 28px;

  // &:first-of-type label {
  //   font-size: 22px;
  // }

  label {
    text-align: right;
    margin-right: 30px;
    cursor: inherit;
  }

  input {
    width: 270px;
    padding: 10px 38px 10px 14px;
    font-size: 18px;
    font-weight: $weight-regular;
    border: unset;
    border-radius: 20px;
    text-align: right;

    &[readonly] {
      background-color: rgba($color-navy, 0.1);
      cursor: not-allowed;
    }

    &:focus {
      border: unset;
    }
  }

  .input-wrapper {
    position: relative;

    &:after {
      content: '€';
      position: absolute;
      top: 50%;
      right: 18px;
      font-size: 22px;
      transform: translateY(-50%);
    }
  }
}

.calcul__mentions {
  padding: 0 16px;
  font-size: 15px;
  color: rgba($color-navy, 0.5);
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(medium down) {
  .nav-calcul__wave {
    width: 50%;
  }
  .nav-calcul__item {
    width: 50%;
  }
}

@include breakpoint(small only) {
  .calcul .wrapper {
    padding: 50px 1rem 190px;
  }

  .nav-calcul__wave {
    width: 50%;
  }
  .nav-calcul__item {
    width: 50%;
  }

  .form-group {
    flex-direction: column;
    margin-bottom: 28px;

    label {
      text-align: center;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}
